import React, { Component, Fragment } from "react";
import { Row, Col, Container, Button, Alert } from "react-bootstrap";
import SmallBanner from "../../other/SmallBanner";
import $ from "jquery";
import { withRouter } from "react-router-dom";
import Footer from "../../other/Footer";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";

var wh;

function basicEffects() {
    $(".end-card").css({ height: `auto` });
    let cardHeight = 0;
    $(".end-card").each(function () {
        if ($(this).outerHeight() > cardHeight) cardHeight = $(this).outerHeight();
    });
    $(".end-card").css({ height: `${cardHeight}px` });
    if ($(".loading-spoon").hasClass("show")) {
        $("#intro").delay(1000).fadeTo(400, 1);
    } else {
        $("#intro").delay(500).fadeTo(400, 1);
    }
}

class Intro extends Component {
    componentDidMount() {
        this.props.onHideSpinner(200);
        wh = window.innerHeight < window.outerHeight ? window.innerHeight : window.outerHeight;
        basicEffects();

        window.addEventListener("scroll", this.scroll);
        window.addEventListener("resize", this.resize);
    }

    scroll = () => {
        if (document.getElementById("intro")) {
            const objectOffset = $(`#introCards`).offset();
            const distance = window.scrollY;
            if (distance + wh - 200 >= objectOffset.top) $(`#introCards`).fadeTo(600, 1);
        }
    };

    resize = () => {
        basicEffects();
    };

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scroll);
    }

    handleRedirect = (e, url) => {
        e.preventDefault();
        this.props.onShowSpoonLoader();
        setTimeout(() => this.props.history.push(url), 700);
    };

    render() {
        const { frontURL, t, onShowSpoonLoader } = this.props;
        return (
            <Fragment>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <SmallBanner
                            frontURL={frontURL}
                            bgBottom={`${frontURL}/images/small-banners/b2-bottom.webp`}
                            bgDesktop={`${frontURL}/images/small-banners/b2.webp`}
                            title={t("intro.title")}
                            subtitle={t("intro.subtitle")}
                        />
                    </Col>
                </Row>
                <Container id="intro" style={{ opacity: 0 }}>
                    <Row>
                        <Col>
                            <Alert variant="info" className="mt-5">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                {t("intro.designDocInProgress")}
                            </Alert>
                        </Col>
                    </Row>
                    {/*  <Row>
                        <Col xs={12} lg={{ span: 4, offset: 4 }} className="pl-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3 text-center">
                                    {t("url.text.designDoc")} 2024
                                    <br />"{t("designDocTitle")}"
                                </p>
                                <a href={`/files/Design_Doc_lyzkon_2024_${i18n.language}.pdf`} className="d-block mb-2 btn btn-outline-primary btn-lg">
                                    {t("download")}
                                </a>
                            </div>
                        </Col>
                    </Row> */}
                    {/*<Row>
                        <Col className="text-center my-5 white border py-3 bg-white-opacity">
                            <h1 className="h1">{t("intro.inProgress")}</h1>
                            <Row>
                                <Col xs={12} lg={{ span: 8, offset: 2 }}>
                                    <p className="h4 mb-0">{t("intro.inProgressTxt")}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col xs={12} className="text-justify my-5">
                            {i18next.language === "pl" ? (
                                <div>
                                    <h1 className="h2 text-center my-5">
                                        Wizja eksplozji przeciążonego reaktora wisiała nad Ośrodkiem ‘Sosna’ od czasu zniszczenia maszyny odpowiedzialnej za tworzenie Mgły.
                                    </h1>
                                    <p>
                                        Nie powstrzymało to jednak napływu przybyszy pragnących odnalezienia zaginionych przedwojennych skarbów. Poszukiwacze z pomocą Przewodników
                                        otworzyli cztery zamknięte od wojny laboratoria i poznali ich sekrety: ślady eksperymentów z gazami bojowymi, banki danych oparte o SI i
                                        prototypy broni ujrzały światło dzienne.
                                    </p>
                                    <p>
                                        Przy okazji miasteczko zyskało i straciło nowego wroga w postaci ciężko chorej Krezus - herszt grupy raidersów. Mieszkańcy, niechętni
                                        współpracy z liderką, najpierw zabili jej agentów, a potem samą Krezus. W odwecie, raidersi zaatakowali miasteczko, pozostawiając za sobą
                                        wielu rannych i zabitych.
                                    </p>
                                    <p>
                                        W kulminacyjnym momencie, gdy było wiadomo, że rdzeń od stopienia dzielą zaledwie godziny, grupa śmiałków dotarła do napromieniowanej hali
                                        reaktora ‘Jurata’ i wymieniła rdzeń, co uratowało Ośrodek ‘Sosna’ i wszystkich przebywających w jego okolicy.
                                    </p>
                                    <p>
                                        Przywrócenie zasilania pozwoliło ocalić liczne okruchy wiedzy i technologii sprzed wojny, jednak obudziło też nieaktywny od dekad system
                                        monitorowania Ośrodka. Jeden z ekranów w obozie Mirażu przechwycił sygnał budzących się komputerów kompleksu. Działalność poszukiwawcza
                                        została zaklasyfikowana jako nieautoryzowany dostęp i system wysłał SOS do Wojskowej Służby Wewnętrznej, jednak nie otrzymał odpowiedzi.
                                    </p>
                                    <p>
                                        Nie mógł. WSW razem z resztą sił zbrojnych Polskiej Rzeczypospolitej Ludowej przestały istnieć 23 października 2077. Mimo to, sygnał
                                        alarmowy znalazł odbiorcę. Ostatni komunikat przechwycony przez Miraż brzmiał “Otwieranie sekcji KATEDRA”.
                                    </p>
                                    <h2 className="h2 text-center my-5">Od tych wydarzeń minęły dwa tygodnie…</h2>
                                    <p>
                                        Duch Lasu i jego stadko mutantów opuścili gniazdo w Laboratorium nr 3. Po ich zniknięciu, w części ośrodka Mgła się przerzedziła. Patrole
                                        potwierdziły, że do wcześniej odkrytych budynków da się dotrzeć bez pomocy Przewodników, jednak niezbadane południe wciąż okryte jest
                                        śmiercionośną barierą.
                                    </p>
                                    <p>
                                        Mieszkańcy miasteczka nazwali te tereny ‘Foglands’, a Przewodnicy wprawieni w operowaniu w tym nieprzyjaznym środowisku doszli do wniosku,
                                        że im też w końcu należy się godziwa zapłata za pracę i stworzyli swoją gildię. Każda ekipa chętna wyruszyć na Foglands musi zgłosić się do
                                        biura Gildii Przewodników i opłacić przeprawę.
                                    </p>
                                    <p>
                                        Natura nie lubi próżni, więc raidersi wykorzystali okazję i rozbili obóz w Laboratorium nr 10. Kopią tam wciąż, licząc na to, że znaleziony
                                        wcześniej przez poszukiwaczy prototyp broni to nie jedyny skarb ukryty w magazynach. Mają też nowego herszta - Barona. Jego zamiary wobec
                                        Ośrodka ‘Sosna’ i miasteczka na razie nie są znane.
                                    </p>
                                    <p>
                                        Raidersi - jak i mutanty - to coś powszechnego na pustkowiach. Patrole zauważyły też jednak coś niecodziennego: ruch w starym Biurze Ochrony
                                        kompleksu. Jakaś konstrukcja powstaje wewnątrz fortyfikacji, w nocy światła lamp przeczesują przedpole murów.
                                    </p>
                                    <p>
                                        Pomiędzy Biurem Ochrony a reaktorem widać grupy ludzi w białych kitlach w obstawie ciężko opancerzonych żołnierzy. Nie wiemy kim są Ci
                                        ludzie ani jaki jest ich cel. Jednak zbrojne patrole zapuszczają się coraz bliżej miasteczka, a według przewodników widziano owe grupy
                                        powracające z Foglands.
                                    </p>
                                    <p>
                                        Ośrodek ‘Sosna’ ma jeszcze kilka tajemnic do odkrycia i wydaje się, że tym razem w wyścigu pojawili się nowi zawodnicy. Kolejne skarby są na
                                        wyciągnięcie ręki, jednak cena za ich odnalezienie może być wyższa niż poprzednio…
                                    </p>
                                </div>
                            ) : i18next.language === "en" ? (
                                <div>
                                    <h1 className="h2 text-center my-5">
                                        An explosion of an overloaded reactor has loomed over the ‘Sosna’ Facility since the destruction of the machine responsible for creating the
                                        Fog.
                                    </h1>
                                    <p>
                                        However, this did not stop the influx of outsiders eager to find lost pre-war treasures. The prospectors, with the help of the Guides,
                                        opened four laboratories that had been closed since the war and learned their secrets: traces of war gas experiments, AI-based data banks
                                        and weapons prototypes saw the light of day.
                                    </p>
                                    <p>
                                        In the process, the town gained and lost a new enemy in the form of the gravely ill Krezus, the ringleader of a group of raiders. The
                                        inhabitants, unwilling to cooperate with the leader, first killed her agents and then Krezus herself. In retaliation, the raiders attacked
                                        the town, leaving many injured and dead in their wake.
                                    </p>
                                    <p>
                                        At the climax, when it was known that the core was only hours away from melting, a group of daredevils reached the irradiated hall of the
                                        ‘Jurata’ reactor and replaced the core, which saved the ‘Sosna’ Facility and everyone in its proximity.
                                    </p>
                                    <p>
                                        The restoration of power saved numerous remnants of pre-war knowledge and technology, but it also awoke the Facility's monitoring system,
                                        which had been dormant for decades. One of the screens in the Mirage camp intercepted the signal of the complex's awakening computers. The
                                        search activity was classified as unauthorised access and the system sent an SOS to the WSW (Military Internal Service), but received no
                                        response.
                                    </p>
                                    <p>
                                        It could not. The WSW, along with the rest of the armed forces of the Polish People's Republic, ceased to exist on 23 October 2077.
                                        Nevertheless, the distress signal found a recipient. The last message intercepted by Mirage was ‘Opening Section KATEDRA’.
                                    </p>
                                    <h2 className="h2 text-center my-5">Two weeks have passed since these events....</h2>
                                    <p>
                                        The Spirit of the Forest and his pack of mutants have left their nest in Laboratory 3. Following their disappearance, the Fog has thinned in
                                        parts of the facility. Patrols have confirmed that the previously discovered buildings can be reached without the help of the Guides, but
                                        the unexplored south is still shrouded in a deadly barrier.
                                    </p>
                                    <p>
                                        The townspeople named the area 'Foglands', and the Guides, skilled in operating in this hostile environment, decided that they too should
                                        finally be paid a fair wage for their work and created their guild. Any team wishing to set off for the Foglands must report to the Guides'
                                        Guild office and pay for the crossing.
                                    </p>
                                    <p>
                                        As nature does not tend to leave a void, the raiders seized the opportunity to set up camp in Laboratory 10. They are still digging there,
                                        hoping that the weapon prototype found earlier by the prospectors is not the only treasure hidden in the warehouses. They also have a new
                                        chieftain - the Baron. His intentions towards the ‘Sosna’ Facility and the town are as yet unknown.
                                    </p>
                                    <p>
                                        Raiders - as well as mutants - are something common in the wastelands. However, the patrols have also noticed something unusual: movement in
                                        the old Security Office of the complex. Some kind of construction is taking place inside the stronghold, at night the lights of the lamps
                                        scour the foreground of the walls.
                                    </p>
                                    <p>
                                        Between the Security Office and the reactor, groups of people in white lab coats have been seen traveling, escorted by heavily armoured
                                        soldiers. We do not know who these people are or what their purpose is. However, armed patrols are venturing closer and closer to the town,
                                        and according to the guides some of these groups have been seen returning from the Foglands.
                                    </p>
                                    <p>
                                        The ‘Sosna’ Facility still has a few more secrets to uncover and it seems there are new competitors in the race this time. More treasures
                                        are at hand, but the price for finding them may be higher than before....
                                    </p>
                                </div>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                    <Row className="no-gutters" id="introCards" style={{ opacity: 0 }}>
                        <Col xs={12} className="text-center my-5">
                            <p className="h2 text-uppercase">{t("intro.wannaKnowMore.title")}</p>
                        </Col>
                        <Col xs={12} lg={4} className="pr-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("intro.wannaKnowMore.mechanics")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.basicMechanics")}`)} variant="outline-primary" size="lg">
                                    {t("intro.wannaKnowMore.mechanicsBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="px-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.accr")}</p>
                                <Button
                                    onClick={(e) => this.handleRedirect(e, `${t("url.link.myAccount")}/${t("url.link.accreditation")}/${t("url.link.accrForm")}`)}
                                    variant="outline-primary"
                                    size="lg"
                                >
                                    {t("about.wannaKnowMore.accrBtn")}
                                </Button>
                            </div>
                        </Col>
                        <Col xs={12} lg={4} className="pl-4 py-5">
                            <div className="end-card text-center p-4">
                                <p className="end-card-title py-3">{t("about.wannaKnowMore.program")}</p>
                                <Button onClick={(e) => this.handleRedirect(e, `${t("url.link.program")}`)} variant="outline-primary" size="lg">
                                    {t("about.wannaKnowMore.programBtn")}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Row className="no-gutters">
                    <Col xs={12}>
                        <Footer frontURL={frontURL} t={t} onShowSpoonLoader={onShowSpoonLoader} />
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Intro);
