import React, { Fragment, Component } from "react";
import { Nav, Navbar, NavDropdown, Image, Row, Col, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import i18n from "../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faDownload, faTimes } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

class MainNavbar extends Component {
    state = { navExpanded: false, menuIcon: faBars };

    scroll = () => {
        $("#navbarTranspBg").css("top", `${window.scrollY}px`);
    };

    setNavbarLogo = () => {
        if (window.innerWidth >= 1200 && window.innerWidth < window.innerHeight) {
            document.getElementById("navbarLogoImg").setAttribute("src", `${this.props.frontURL}/images/lyzkon_icon_white.svg`);
        } else {
            document.getElementById("navbarLogoImg").setAttribute("src", `${this.props.frontURL}/images/lyzkon_logo_white.svg`);
        }
    };

    resize = () => {
        this.setNavbarLogo();
    };

    componentDidMount() {
        this.setNavbarLogo();

        $("#mainNavbar .navbar-toggler").on("click", function () {
            if (!$(".navbar-toggler").hasClass("collapsed")) {
                $("body").removeClass("stop-scrolling");
                $("body").unbind("touchmove");
                $("#navbarTranspBg").fadeOut(200);
            } else {
                $("body").addClass("stop-scrolling");
                $("#navbarTranspBg").fadeIn(200);
            }
        });

        window.addEventListener("scroll", this.scroll);
        window.addEventListener("resize", this.resize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("scroll", this.scroll);
    }

    handleChangeRoute = (e, url) => {
        e.preventDefault();

        this.props.onShowSpoonLoader();
        setTimeout(() => {
            $("body").removeClass("stop-scrolling");
            $("body").unbind("touchmove");

            this.setState({ navExpanded: false, menuIcon: faBars });
            $("#mainNavbar .navbar-toggler").removeClass("hideBars");
            $("#mainNavbar .navbar-toggler").addClass("hideTimes");
            $("#mainNavbar .navbar-toggler").addClass("showBars");
        }, 250);
        setTimeout(() => {
            $("#navbarTranspBg").fadeOut(0);
            this.props.history.push(url);
        }, 700);
    };

    handleChangeIcon = () => {
        const { menuIcon } = this.state;
        if (menuIcon === faBars) {
            setTimeout(() => {
                $("#mainNavbar .navbar-toggler").removeClass("hideTimes");
                $("#mainNavbar .navbar-toggler").addClass("hideBars");
            }, 10);
            setTimeout(() => {
                $("#mainNavbar .navbar-toggler").addClass("showTimes");
                this.setState({ menuIcon: faTimes });
            }, 200);
        } else {
            setTimeout(() => {
                $("#mainNavbar .navbar-toggler").removeClass("hideBars");
                $("#mainNavbar .navbar-toggler").addClass("hideTimes");
            }, 10);

            setTimeout(() => {
                $("#mainNavbar .navbar-toggler").addClass("showBars");
                this.setState({ menuIcon: faBars });
            }, 200);
        }
    };

    render() {
        const { navExpanded, menuIcon } = this.state;
        const { t, loggedIn, frontURL, myAccountNotification } = this.props;

        return (
            <Row className="no-gutters">
                <Col>
                    <div id="navbarTranspBg" style={{ display: "none" }}></div>
                    <Navbar
                        collapseOnSelect
                        expand="lg"
                        variant="dark"
                        id="mainNavbar"
                        fixed="top"
                        expanded={navExpanded}
                        onToggle={(expanded) => this.setState({ navExpanded: expanded })}
                    >
                        <Navbar.Toggle id="mainNavbarToggle" aria-controls="responsive-navbar-nav" children={<FontAwesomeIcon icon={menuIcon} />} onClick={this.handleChangeIcon} />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Navbar.Brand as={Link} to={`/`} onClick={(e) => this.handleChangeRoute(e, `/`)} id="navbarLogo">
                                <Image src={`${frontURL}/images/lyzkon_logo_white.svg`} className="w-100" id="navbarLogoImg" />
                            </Navbar.Brand>
                            <Nav className="mr-auto">
                                <Nav.Link as={Link} to={`/${t("url.link.news")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.news")}`)}>
                                    {t("url.text.news")}
                                </Nav.Link>
                                <Nav.Link as={Link} to={`/${t("url.link.accessAndParking")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.accessAndParking")}`)}>
                                    {t("url.text.area")}
                                </Nav.Link>
                                {/*  <NavDropdown title={t("url.text.area")} id="collasible-nav-dropdown2">
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.whereWePlay")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.whereWePlay")}`)}>
                                        {t("url.text.whereWePlay")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.whereWeSleep")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.whereWeSleep")}`)}>
                                        {t("url.text.whereWeSleep")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.accessAndParking")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.accessAndParking")}`)}
                                    >
                                        {t("url.text.accessAndParking")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.foodAndHygiene")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.foodAndHygiene")}`)}
                                    >
                                        {t("url.text.foodAndHygiene")}
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                                <NavDropdown title={t("url.text.event")} id="collasible-nav-dropdown">
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.about")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.about")}`)}>
                                        {t("url.text.about")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.accreditation")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.accreditation")}`)}
                                    >
                                        {t("url.text.accreditation")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.regulations")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.regulations")}`)}>
                                        {t("url.text.regulations")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.program")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.program")}`)}>
                                        {t("url.text.program")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.gallery")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.gallery")}`)}>
                                        {t("url.text.gallery")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.previousEditions")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.previousEditions")}`)}
                                    >
                                        {t("url.text.previousEditions")}
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title={t("url.text.larp")} id="collasible-nav-dropdown">
                                    <NavDropdown.Item as={Link} to={`/${t("url.link.intro")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.intro")}`)}>
                                        {t("url.text.intro")}
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.basicMechanics")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.basicMechanics")}`)}
                                    >
                                        {t("url.text.basicMechanics")}
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item href={`/files/Basic_Mechanics_2025_${i18n.language}.pdf`}>
                                        {t("url.text.basicMechanics")}
                                        <FontAwesomeIcon icon={faDownload} className="ml-2" />
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.advancedMechanics")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.advancedMechanics")}`)}
                                    >
                                        {t("url.text.advancedMechanics")}
                                    </NavDropdown.Item> */}
                                    {/* <NavDropdown.Item href={`/files/Advanced_Mechanics_2024_${i18n.language}.pdf`}>
                                        {t("url.text.advancedMechanics")}
                                        <FontAwesomeIcon icon={faDownload} className="ml-2" />
                                    </NavDropdown.Item> */}
                                    {/*  <NavDropdown.Item
                                        as={Link}
                                        to={`/${t("url.link.accreditationReq")}`}
                                        onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.accreditationReq")}`)}
                                    >
                                        {t("url.text.accreditationReq")}
                                    </NavDropdown.Item> */}
                                    {/* <NavDropdown.Item href={`/files/Design_Doc_lyzkon_2024_${i18n.language}.pdf`}>
                                        {t("url.text.designDoc")}
                                        <FontAwesomeIcon icon={faDownload} className="ml-2" />
                                    </NavDropdown.Item> */}
                                </NavDropdown>
                            </Nav>
                            <Nav>
                                {!loggedIn ? (
                                    <Fragment>
                                        <div className="d-none d-lg-flex">
                                            <Nav.Link
                                                as={Link}
                                                to={`/${t("url.link.register")}`}
                                                id="navLinkRegister"
                                                onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.register")}`)}
                                            >
                                                {t("url.text.register")}
                                            </Nav.Link>
                                            <span className="nav-link-text">{t("url.text.or")}</span>
                                            <Nav.Link
                                                as={Link}
                                                id="navLinkLogin"
                                                to={`/${t("url.link.login")}`}
                                                onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.login")}`)}
                                            >
                                                {t("url.text.login")}
                                            </Nav.Link>
                                        </div>
                                        <div className="d-lg-none pb-4" id="registerAndLogin">
                                            <Nav.Link
                                                as={Link}
                                                to={`/${t("url.link.register")}`}
                                                id="navLinkRegister"
                                                onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.register")}`)}
                                            >
                                                {t("url.text.register")}
                                            </Nav.Link>
                                            <span className="nav-link-text">{t("url.text.or")}</span>
                                            <Nav.Link
                                                as={Link}
                                                to={`/${t("url.link.login")}`}
                                                id="navLinkLogin"
                                                onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.login")}`)}
                                            >
                                                {t("url.text.login")}
                                            </Nav.Link>
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Nav.Link as={Link} to={`/${t("url.link.myAccount")}`} onClick={(e) => this.handleChangeRoute(e, `/${t("url.link.myAccount")}`)}>
                                        {(myAccountNotification && myAccountNotification > 0) === true && (
                                            <span className="badge badge-pill badge-danger mx-1 align-text-bottom">{myAccountNotification}</span>
                                        )}
                                        {t("url.text.myAccount")}
                                    </Nav.Link>
                                )}
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                    <Button
                        id="btnLang"
                        className="btn btn-outline-primary btn-sm"
                        onClick={(e) => {
                            i18n.changeLanguage(i18n.language === "pl" ? "en" : "pl", function () {
                                document.title = t("document.title");
                            });
                        }}
                    >
                        {i18n.language === "pl" ? "en" : "pl"}
                    </Button>
                </Col>
            </Row>
        );
    }
}

export default withRouter(MainNavbar);
